import { gql } from '@faststore/graphql-utils'
import type { Session as SDKSession } from '@faststore/sdk'

import { Person as SDKPerson } from '@faststore/sdk/dist/session/Session'
import type {
  ValidateSessionMutation,
  ValidateSessionMutationVariables,
} from '../../../@generated/graphql/index'
import { request } from '../graphql/request'
// import axios from 'axios'

export const BK_ID_STORAGE_KEY = 'faststore:session:bk_id'
const ONE_DAY = 1 * 24 * 60 * 60 * 1000
const ONE_WEEK = 7 * 24 * 60 * 60 * 1000

export const mutation = gql`
  mutation ValidateSession($session: IStoreSession!, $search: String!) {
    validateSession(session: $session, search: $search) {
      locale
      channel
      country
      postalCode
      currency {
        code
        symbol
      }
      person {
        id
        email
        givenName
        familyName
      }
    }
  }
`

export interface Session extends SDKSession {
  person: Person | null
}
export interface Person extends SDKPerson {
  bk_id?: string | null
}

const fetchAndStoreBkId = async (customer_id: string) => {
  try {
    const result = await fetch('/api/userAccount/getBkid')
    if (result.ok) {
      const json = await result.json()
      if (json && json.bkid) {
        const customer_to_store = {
          customer_id,
          bk_id: json.bkid,
          timestamp: Date.now(),
        }
        localStorage.setItem(
          BK_ID_STORAGE_KEY,
          JSON.stringify(customer_to_store)
        )
        return json.bkid
      }
      const customer_to_store = {
        customer_id,
        bk_id: null,
        timestamp: Date.now(),
      }
      localStorage.setItem(BK_ID_STORAGE_KEY, JSON.stringify(customer_to_store))
      return undefined
    }
  } catch (err) {
    console.error(err)
    return undefined
  }
}

export const validateSession = async (
  session: Session
): Promise<Session | null> => {
  const current_bk_id = session.person?.hasOwnProperty('bk_id')
  if (current_bk_id) {
    delete session.person?.bk_id // This field needs to be deleted in order to avoid type errors on the mutation resolver
  }

  const data = await request<
    ValidateSessionMutation,
    ValidateSessionMutationVariables
  >(mutation, { session, search: window.location.search })

  if (data.validateSession && data.validateSession.person) {
    // We check if the mutation returns fresh data and check if there is a valid bk_id in the localStorage
    let new_bk_id = ''
    const stored_customer = JSON.parse(
      localStorage.getItem(BK_ID_STORAGE_KEY) ?? 'null'
    )
    if (stored_customer) {
      // If there is a stored bk_id we need to check if it's older than 7 days
      const timestamp = stored_customer.timestamp
      const mustRevalidate =
        !timestamp ||
        (timestamp &&
          stored_customer?.bk_id === null &&
          Date.now() - timestamp > ONE_DAY) ||
        (timestamp && Date.now() - timestamp > ONE_WEEK)
      if (!mustRevalidate) {
        const { customer_id, bk_id: stored_bk_id } = stored_customer
        if (
          stored_bk_id &&
          customer_id &&
          customer_id === data.validateSession.person.id
        ) {
          // The stored bk_id is valid so we can pass it to the stored session
          new_bk_id = stored_bk_id
        } else {
          // The stored bk_id is NULL because the service returned an empty object (maybe because the user email was not valid)
          if (
            stored_bk_id === null &&
            customer_id === data.validateSession.person.id
          ) {
            new_bk_id = ''
          } else {
            // The stored bk_id is NOT valid so we need to fetch the new bk_id and replace the stored one
            const fetched_bk_id = await fetchAndStoreBkId(
              data.validateSession.person.id
            )
            if (fetched_bk_id) {
              new_bk_id = fetched_bk_id
            }
          }
        }
      } else {
        // The stored bk_id is OLD so we need to fetch the new bk_id and store the new one
        const fetched_bk_id = await fetchAndStoreBkId(
          data.validateSession.person.id
        )
        if (fetched_bk_id) {
          new_bk_id = fetched_bk_id
        }
      }
    } else {
      // The stored bk_id is NULL so we need to fetch the new bk_id and store the new one
      const fetched_bk_id = await fetchAndStoreBkId(
        data.validateSession.person.id
      )
      if (fetched_bk_id) {
        new_bk_id = fetched_bk_id
      }
    }
    // We then create the newSession object with the bk_id property to be stored in IndexedDB
    const newSession = {
      ...data.validateSession,
      person: {
        ...data.validateSession.person,
        bk_id: new_bk_id,
      },
    }
    return newSession
  }
  // Otherwise, we return the original session object
  return data.validateSession
}